const theme = {
  colors: {
    primary: '#062E4F',
    primary_grey: '#eaeaea',
    secondary: '#7AFDCF',
    secondary_grey: '#83929b',
    orange: '#ffaa2b',
    dark_blue: '#002039',
    dark_grey: '#333333',
    medium_grey: '#d0d0d0',
    light_grey: '#f0f2f1',
    btn_disabled: '#c1c1c1',
    btn_disabled_text: '#9c9c9c',
    bg_row_grey: '#d3d3d3',
    text_footer: '#000000',
    text_primary: '#666666',
    text_dark: '#2b2a29',
    text_light: '#6d6d6d',
    text_quote: '#574d48',
    bg_light: '#f7f7f7',
    white: '#ffffff',
    black: '#000000',
    bg_row_hover: '#f4e4b8',
    state_available: '#55a630',
    state_reserved: '#fb8b24',
    state_sold: '#e01e37',
    transparent: 'transparent',
    line: 'rgba(43,42,41, 0.15)',
    img_overlay: 'rgba(43,42,41, 0.5)',
    tab_active: '#e5e5e5',
    bg_plan_row: '#e5e5e5',
    bg_footer: '#574d48',
    footer_text: '#cccccc',
    bg_menu_overlay: 'rgba(249,249,249, 0.9)',
    text_list_th: '#747474',
    bg_list_tr: '#f3f2f2',
    error: '#d12d28',
  }
};

export default theme;