import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    width: 540px;
  }

  @media (min-width: 768px) {
    width: 620px;
  }

  @media (min-width: 992px) {
    width: 860px;
  }

  @media (min-width: 1200px) {
    width: 1040px;
  }

  @media (min-width: 1400px) {
    width: 1290px;
  }

  @media (min-width: 1600px) {
    width: 1520px;
  }
`;

export const HalfContentWrapper = styled.div`
  width: 100%;
  /* margin: 0 0 0 auto; */
  margin-left: 0;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    width: 270px;
  }

  @media (min-width: 768px) {
    width: 310px;
  }

  @media (min-width: 992px) {
    width: 430px;
  }

  @media (min-width: 1200px) {
    width: 520px;
  }

  @media (min-width: 1400px) {
    width: 645px;
  }

  @media (min-width: 1600px) {
    width: 760px;
  }
`;